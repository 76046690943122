.media_text_block{
  font-family: var(--font-body-family);
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
  min-height: 40vh;
}
.grid_item{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}
.media_img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 450px;
}
.media_img>img{
    width: 100%;
  	height: auto;
}
.coloured_block{
    position: absolute;
    top: 30px;
    bottom: 50px;
    width: 100%;
}
.img_wrapper_coloured_block{
    position: absolute;
    top: 20px;
    right: 30px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img_no_clour{
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 50vh;
}
.img_wrapper_coloured_block>img{
    max-width: 100%;
    max-height: 100%;
}
.media_text{
    height: calc(100% - 40px);
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
}
.media_text>h4{
  font-family: var(--font-heading-family);
  font-style: var(--font-heading-style);
  font-weight: var(--font-heading-weight);
}

@media screen and (max-width: 1150px){
    .single_reasons{
        height: auto;
    }
}

@media screen and (max-width: 890px){
    .media_text_block{
        grid-template-columns:  repeat(1, 1fr);
    }
    .grid_item{
        min-height: 40vh;
    }
}

@media screen and (max-width: 450px){
    .cta_wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}